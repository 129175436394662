import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />

    <div className={"container"}>
      <div className={"content"}>
        <div className={"title"}>
          <h1>Mikrofon App Privacy Policy</h1>
        </div>

        <h3>Information Collection and Use</h3>
        <p>
          When starting the Mikrofon for the first time, the application will
          request access to your input audio devices for the sole purpose of
          providing mute and unmute funtionality. Mikrofon does not record or
          store input from your devices for any purpose.
        </p>

        <h3>Changes to This Privacy Policy</h3>
        <p>
          We may update our Privacy Policy from time to time. We advise that you
          review this page periodically for any changes. We will notify you of
          any changes by posting the new Privacy Policy on this page.
        </p>

        <h3>Consent</h3>
        <p>
          By using our application, you hereby consent to our Privacy Policy and
          agree to its Terms and Conditions.
        </p>
      </div>
    </div>

    <div className={"container"}>
      <div className={"content"}>
        <div className={"title"}>
          <h1>Site Privacy Policy</h1>
        </div>

        <p>
          At Kapsel, one of our main priorities is the privacy of our visitors.
          This Privacy Policy document contains types of information that is
          collected and recorded by Kapsel and how we use it.
        </p>
        <p>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us through email at
          contact@kapsel.co
        </p>

        <h3>Cookies and Web Beacons</h3>
        <p>
          Kapsel uses cookies to store information including visitors'
          preferences, and the pages on the website that the visitor accessed or
          visited. The information is used to optimize the users' experience by
          customizing our web page content based on visitors' browser type
          and/or other information.
        </p>

        <h3>Children's Information</h3>
        <p>
          Kapsel does not collect any Personal Identifiable Information from
          children under the age of 13. If you think that your child provided
          this kind of information on our website, we strongly encourage you to
          contact us immediately and we will do our best efforts to promptly
          remove such information from our records.
        </p>

        <h3>Changes to This Privacy Policy</h3>
        <p>
          We may update our Privacy Policy from time to time. We advise that you
          review this page periodically for any changes. We will notify you of
          any changes by posting the new Privacy Policy on this page.
        </p>

        <h3>Consent</h3>
        <p>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its Terms and Conditions.
        </p>
      </div>
    </div>
  </Layout>
)

export default PrivacyPage
